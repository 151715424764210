import { Routes, Route } from "react-router-dom";
import { Home } from "./pages/home";
export const AppNavigation = () => {
  return (
    <div className="content">
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </div>
  );
};
