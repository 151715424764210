import { useTranslation } from "react-i18next";
import { LandingHeader } from "../components/landing/image";
import { Projects } from "../components/projects";
import { FaEnvelope, FaEnvelopeSquare, FaGithubSquare, FaIdCard, FaLinkedin, FaMailBulk, FaMailchimp, FaVoicemail, FaYoutube, FaYoutubeSquare } from "react-icons/fa";
import { useScript } from "../hooks/useScript";

export const Home = () => {
	const { t } = useTranslation();
    
    useScript("./scripts/scripts.js");

	return (
		<div>
			<LandingHeader />
            
			<div className="p-4 mb-4">
				<div className="grid grid-cols-1 md:grid-cols-4 gap-4">
					<div className="col-span-1 mt-[10%] p-2">
						<div>
							<img
								src={require("../images/profilepic.jpg")}
								style={{
									border: "3px solid black",
									borderRadius: "25px",
								}}
                                className="md:ml-10 xl:ml-20"
								width={125}
								height={125}
								alt="Profile picture"
							/>
							<div className="xs:w-full md:w-9/12 xxs:gap-y-3 md:gap-y-4 grid" style={{margin: "auto"}}>
                                <div>
                                    <div className="text-xl">
                                        <span>Pedro Martins</span>
                                    </div>
                                    <div className="opacity-70">
                                        <span>Fullstack Developer</span>
                                    </div>
                                </div>

                                <div>
                                    <div className="flex gap-2">
                                        <a href="https://www.linkedin.com/in/pedro-martins-323118124/" title="LinkedIn" target="_blank">
                                            <FaLinkedin size={30} />
                                        </a>
                                        <a href="https://www.github.com/pedroervalho/" title="GitHub" target="_blank">
                                            <FaGithubSquare size={30} />
                                        </a>
                                        <a href="https://www.youtube.com/" title="Youtube" target="_blank">
                                            <FaYoutubeSquare size={30} />
                                        </a>
                                        <a href="mailto:pedroervalho@gmail.com" title="E-mail @ pedroervalho@gmail.com" target="_blank">
                                            <FaEnvelopeSquare size={30} />
                                        </a>
                                    </div>
                                </div>
                                
                                <div className="leading-tight text-sm">
                                    <div>
                                        <span>
                                            Programming since 2010, I love turning ideas into real projects and solutions. 
                                            <br />
                                            Simply enjoying making it happen!
                                        </span>
                                    </div>
                                </div>
                                
                                <div className="text-sm">
                                    <span>Experience</span>
                                    <div className="pl-1 leading-tight">
                                        <div>
                                            <span>AnoSoftware</span>, <span>2017 - {t("present")}</span>
                                        </div>
                                        <div>
                                            <span>BA in Computer Engineering</span>
                                            <span> @ </span>
                                            <a className="decoration-solid" href="https://www.ipvc.pt/en/">IPVC</a>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="text-sm">
                                    <span>Technology</span>
                                    <div className="pl-1 leading-tight">
                                        <div>
                                            Java, Javascript, Typescript, SQL
                                        </div>
                                        <div>
                                            Primefaces, ReactJS
                                        </div>
                                        <div>
                                            Spring, Hibernate
                                        </div>
                                    </div>
                                </div>
							</div>
						</div>
					</div>

					<div className="col-span-3 border-indigo-600">
						<div id="projects">
                            <Projects />
                        </div>
					</div>
				</div>
			</div>
		</div>
	);
};
